import React from "react";
import styled from "styled-components";
import { mediaQueries } from "../theme/theme";

const WrapperBase = styled.div({
  margin: "auto",
  [mediaQueries.xSmallMax]: {
    maxWidth: 380,
    padding: 12,
  },
  [mediaQueries.mediumMax]: {
    maxWidth: 520,
    padding: 12,
  },
  [mediaQueries.largeMax]: {
    maxWidth: 640,
    padding: 24,
  },
  [mediaQueries.xLargeMax]: {
    maxWidth: 700,
    padding: 24,
  },
  [mediaQueries.xLargeMin]: {
    maxWidth: 1200,
    padding: 24,
  },
});
function Wrapper(props) {
  return <WrapperBase>{props.children}</WrapperBase>;
}

export { Wrapper };
