import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lightTheme, darkTheme, Theme } from "../theme/theme";
import { AppState } from "../reducers/blogState";

interface IThemeProviderProps {
  children: JSX.Element | JSX.Element[];
  theme?: Theme;
}

const getThemeFromStorage = (): boolean => {
  if (typeof window !== "undefined") {
    const isThemeInLocalStorage = window.localStorage.getItem("isDarkTheme");
    if (
      isThemeInLocalStorage !== null &&
      isThemeInLocalStorage !== undefined &&
      isThemeInLocalStorage !== "undefined"
    ) {
      return JSON.parse(isThemeInLocalStorage);
    }
  }
  return false;
};

const supportsDarkMode = () => {
  if (typeof window !== "undefined") {
    return window.matchMedia("(prefers-color-scheme: dark)").matches === true;
  }
  return false;
};

const ThemeProvider = (props: IThemeProviderProps): JSX.Element => {
  const isDark = getThemeFromStorage();
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state: AppState) => state.isDark);

  const setDarkTheme = (newState: boolean) =>
    dispatch({
      type: "SET_IS_DARK",
      isDark: newState,
    });

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (isDarkTheme !== null && isDarkTheme !== undefined) {
        window.localStorage.setItem("isDarkTheme", JSON.stringify(isDarkTheme));
      }
    }
  }, [isDarkTheme]);

  useEffect(() => {
    if (isDark) {
      setDarkTheme(isDark);
    } else if (supportsDarkMode()) {
      setDarkTheme(true);
    }
  }, []);

  return <>{props.children}</>;
};

const useTheme = (): Theme => {
  return useSelector((state: AppState) => state.isDark)
    ? darkTheme
    : lightTheme;
};

export { ThemeProvider, useTheme };
