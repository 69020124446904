import React from "react";
import styled from "styled-components";
import { ThemeProvider } from "./ThemeProvider";
import { Nav } from "../components/nav";
import logo from "../../content/assets/hellodeary-icon.png";

import { initializeIcons } from "@fluentui/react";
import { Text } from "./typography";
import "./layout.css";
import { Body } from "./body";
import { Wrapper } from "./wrapper";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

initializeIcons();

const LogoImg = styled.img((props) => ({
  maxWidth: props.width || 48,
  paddingRight: 14,
}));

function HelloDearyLogo(props: { width?: number }) {
  return <LogoImg src={logo} alt="a digital heard disintegrating" />;
}

function Layout(props) {
  const { location, children } = props;
  const blogPath = `${__PATH_PREFIX__}/blog/`;

  const pageLinks = [
    { id: "1111", name: "Home", link: "/" },
    { id: "2222", name: "Blog", link: "/blog/" },
    { id: "3333", name: "Contact", link: "/contact/" },
    { id: "4444", name: "About", link: "/about/" },
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "SET_IS_LOADED",
      isLoaded: true,
    });
  }, []);

  return (
    <ThemeProvider>
      <Body>
        <Wrapper>
          <header>
            <Nav pageLinks={pageLinks} {...props} />
            <Text variant="xLarge_C">
              <HelloDearyLogo />
              Hello Deary
            </Text>
          </header>
          <main>{children}</main>
        </Wrapper>
      </Body>
    </ThemeProvider>
  );
}

export default Layout;
