import { CSSProperties } from "react";

export type fontKey =
  | "small_B"
  | "small_C"
  | "small_D"
  | "small_DD"
  | "medium_C"
  | "medium_D"
  | "large_B"
  | "large_C"
  | "large_D"
  | "xLarge_C";

export type FontStyles = {
  [key in fontKey]: CSSProperties;
};

export const TextVariants: { [key in fontKey]: fontKey } = {
  small_B: "small_B",
  small_C: "small_C",
  small_D: "small_D",
  small_DD: "small_DD",
  medium_C: "medium_C",
  medium_D: "medium_D",
  large_B: "large_B",
  large_C: "large_C",
  large_D: "large_D",
  xLarge_C: "xLarge_C",
};

export const fonts: FontStyles = {
  small_B: {
    fontFamily: "canada-type-gibson",
    fontSize: ".85rem",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: ".85rem",
  },
  small_C: {
    fontFamily: "canada-type-gibson",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1rem",
  },
  small_D: {
    fontFamily: "canada-type-gibson",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "1rem",
    letterSpacing: ".04rem",
  },
  small_DD: {
    fontFamily: "canada-type-gibson",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "1rem",
  },
  medium_C: {
    fontFamily: "canada-type-gibson",
    fontSize: "1.25rem",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "1.5rem",
    letterSpacing: "0.025rem",
  },
  medium_D: {
    fontFamily: "canada-type-gibson",
    fontSize: "1.25rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1.5rem",
    letterSpacing: "0.025rem",
  },
  large_B: {
    fontFamily: "canada-type-gibson",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "32px",
    letterSpacing: "0em",
    marginBottom: 0,
  },
  large_C: {
    fontFamily: "canada-type-gibson",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "43px",
    letterSpacing: "0em",
    marginBottom: 0,
  },
  large_D: {
    fontFamily: "canada-type-gibson",
    fontSize: "48px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "52px",
    letterSpacing: "0em",
  },
  xLarge_C: {
    fontFamily: "canada-type-gibson",
    fontSize: "72px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "86px",
    letterSpacing: "0em",
    maxWidth: 560,
  },
} as const;
