export interface Shadows {
  elevation2: string;
  elevation4: string;
  elevation8: string;
  elevation16: string;
  elevation32: string;
  elevation64: string;
  elevation96: string;
  elevation192: string;
  elevationMax: string;
}

export const shadows: Shadows = {
  elevation2:
    "0px 0.15px 0.45px rgba(0, 0, 0, 0.13), 0px 0.8px 1.8px rgba(0, 0, 0, 0.15)",
  elevation4:
    "0px 0.3px 0.9px rgba(0, 0, 0, 0.13), 0px 1.6px 3.6px rgba(0, 0, 0, 0.15)",
  elevation8:
    "0px 0.6px 1.8px rgba(0, 0, 0, 0.13), 0px 3.2px 7.2px rgba(0, 0, 0, 0.15)",
  elevation16:
    "0px 1.2px 3.6px rgba(0, 0, 0, 0.13), 0px 6.4px 14.4px rgba(0, 0, 0, 0.15)",
  elevation32:
    "0px 2.4px 7.2px rgba(0, 0, 0, 0.18), 0px 12.8px 28.8px rgba(0, 0, 0, 0.22)",
  elevation64:
    "0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22)",
  elevation96:
    "0px 7.2px 21.6px rgba(0, 0, 0, 0.18), 0px 38.4px 86.4px rgba(0, 0, 0, 0.22)",
  elevation192:
    "0px 14.4px 43.2px rgba(0, 0, 0, 0.11), 0px 76.8px 172.8px rgba(0, 0, 0, 0.13)",
  elevationMax:
    "0px 14.4px 43.2px rgba(0, 0, 0, 0.11), 0px 76.8px 172.8px rgba(0, 0, 0, 0.13)",
};
