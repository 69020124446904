import * as React from "react";
import { useTheme } from "./ThemeProvider";
import { fontKey, TextVariants } from "../theme/fonts";

interface TextProps
  extends React.HTMLProps<HTMLParagraphElement & HTMLHRElement> {
  variant: fontKey;
  children: React.ReactChild | React.ReactChildren | React.ReactNode | string;
  type?: "p" | "h1" | "h2" | "h3" | "h4" | "li" | "a";
  color?: string;
}

function Text(props: TextProps) {
  let ComponentArg1: any = null;
  let ComponentArg2: any = null;
  const theme = useTheme();
  const { type } = props;

  if (theme && theme.fonts) {
    const style = {
      color: props.color || theme.semanticColors.bodyText,
      ...theme.fonts[props.variant],
      ...props.style,
    };
    ComponentArg2 = {
      ...props,
      style,
    };
    switch (props.variant) {
      case TextVariants.small_B:
        ComponentArg1 = type || "span";
        break;
      case TextVariants.small_C:
        ComponentArg1 = type || "span";
        break;
      case TextVariants.small_D:
        ComponentArg1 = type || "span";
        break;
      case TextVariants.small_DD:
        ComponentArg1 = type || "span";
        break;
      case TextVariants.medium_C:
        ComponentArg1 = type || "p";
        break;
      case TextVariants.medium_D:
        ComponentArg1 = type || "span";
        break;
      case TextVariants.large_B:
        ComponentArg1 = "h4";
        break;
      case TextVariants.large_C:
        ComponentArg1 = "h3";
        break;
      case TextVariants.large_D:
        ComponentArg1 = "h2";
        break;
      case TextVariants.xLarge_C:
        ComponentArg1 = "h1";
        break;
      default:
        console.error("component failed to initialize");
        ComponentArg1 = "span";
        ComponentArg2 = undefined;
        break;
    }
  }

  if (ComponentArg1 && ComponentArg2) {
    return React.createElement(ComponentArg1, ComponentArg2, props.children);
  } else return <></>;
}

export { Text };
