import React, { useEffect, useState } from "react";
import { Text } from "./typography";
import { PageProps, Link } from "gatsby";
import styled from "styled-components";
import { Theme } from "../theme/theme";
import { Toggle } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../reducers/blogState";
import { useTheme } from "./ThemeProvider";
import sunIcon from "../../content/assets/sun.svg";
import moonIcon from "../../content/assets/moon.svg";

interface PageLink {
  name: string;
  link: string;
  id: string;
}
interface NavProps extends PageProps {
  pageLinks: Array<PageLink>;
  toggleTheme: () => void;
  checked: boolean;
}

const StyledText = styled(Text)`
  margin: 10px;
  color: ${(props) => props.theme.semanticColors.linkText};
  ::visited {
    color: ${(props) => props.theme.semanticColors.linkText};
  }
}
`;

const NavMain = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  :first-child {
    margin-left: -10px;
  }
`;

const NavLink = (props: {
  linkName: string;
  id: string;
  to: string;
  theme: Theme;
}) => (
  <Link
    key={props.id}
    to={props.to}
    style={{
      outlineColor: props.theme.semanticColors.bodyText,
    }}
  >
    <StyledText theme={props.theme} variant="small_D">
      {props.linkName}
    </StyledText>
  </Link>
);

const StyledImg = styled.img((props: { isDarkTheme: boolean }) => ({
  height: 38,
  width: 38,
  filter: `invert(${props.isDarkTheme ? "100%" : "0%"})`,
}));

function ThemeIcon(props: { isDarkTheme: boolean }) {
  const { isDarkTheme } = props;
  const iconSrc = isDarkTheme ? moonIcon : sunIcon;
  return <StyledImg src={iconSrc} isDarkTheme={isDarkTheme} />;
}

const FlexDiv = styled.div({
  display: "flex",
  alignItems: "center",
});

function Nav(props: NavProps) {
  const dispatch = useDispatch();
  const toggleTheme = () =>
    dispatch({
      type: "TOGGLE_THEME",
    });
  const isDarkTheme = useSelector((state: AppState) => state.isDark);
  const theme = useTheme();
  const { pageLinks } = props;

  let isLocalOrigin = false;
  if (typeof window !== "undefined") {
    isLocalOrigin = location.origin === "http://localhost:8000";
  }

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <NavMain>
      <div>
        {pageLinks.map((pageLink, index) => (
          <NavLink
            key={index}
            linkName={pageLink.name}
            theme={theme}
            id={pageLink.id}
            to={pageLink.link}
          />
        ))}
        {isLocalOrigin && (
          <NavLink
            linkName="Feature Flags"
            theme={theme}
            id="featurePanel"
            to="/featurePanel"
          />
        )}
      </div>
      <FlexDiv>
        <ThemeIcon isDarkTheme={isDarkTheme} />
        {isLoaded && (
          <Toggle
            checked={isDarkTheme}
            onChange={toggleTheme}
            styles={{
              root: {
                marginBottom: 0,
                marginLeft: 8,
              },
              pill: {
                backgroundColor: theme.semanticColors.bodyBackgroundAlt,
                ":hover": {
                  backgroundColor: theme.semanticColors.bodyBackgroundChecked,
                },
                ":focus": {
                  outlineColor: theme.semanticColors.bodyText,
                  outlineOffset: 3,
                },
                ":focus::after": {
                  outline: "none !important",
                  position: "unset !important",
                },
              },
            }}
          />
        )}
      </FlexDiv>
    </NavMain>
  );
}

export { Nav };
