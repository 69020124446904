import React from "react";
import styled from "styled-components";
import { Theme } from "../theme/theme";
import { useTheme } from "./ThemeProvider";
import { useSelector } from "react-redux";
import { AppState } from "../reducers/blogState";

const StyledBody = styled.div((props: { theme: Theme; isLoaded: boolean }) => ({
  background: props.theme.semanticColors.bodyBackground,
  height: "100%",
  minHeight: "100vh",
  opacity: props.isLoaded ? 1 : 0,
  transition: "ease-in 350ms",
}));

function Body(props) {
  const theme = useTheme();
  const isLoaded = useSelector((state: AppState) => state.isLoaded);

  return (
    <StyledBody theme={theme} isLoaded={isLoaded}>
      {props.children}
    </StyledBody>
  );
}

export { Body };
